$primary: #A69B95;
$primary_lighter: lighten($primary,20);
$primary_darker: darken($primary,5);
$secondary: #53585A;
$secondary_lighter: lighten($secondary,20);
$secondary_darker: darken($secondary,5);
$border-radius:0;

:export {
    primary: $primary;
    primary_lighter: $primary_lighter;
    primary_darker: $primary_darker;
    secondary: $secondary;
    secondary_lighter: $secondary_lighter;
    secondary_darker: $secondary_darker;
}