@import "./theme.scss";
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "swiper/css";
@import "swiper/css/navigation";
@import "swiper/css/pagination";
@import "swiper/css/effect-creative";



.App .page {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  font-size: 16px;
}

a{
  text-decoration: none;
}

h1{
  font-size: 2rem;
}
h2{
  font-size: 1.125rem;
}

li{
  list-style: none;
}

.btn{
  border-radius: 0;
}
.swiper-horizontal > .swiper-pagination-bullets{

  bottom: 10px;

}

.swiper-pagination-bullet{
  width: 1rem;
  height: 1rem;
}

.swiper-pagination-bullet-active{
  background:$secondary;
}

.App-header {
  background-color: $primary;   

}

.App-container{
  background-color: $secondary;
  color:$white;
  flex-grow:1;

  & > *{
    padding: 0;
  }
}

.img-cover{
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.img-contain{
  object-fit: contain;
  height: 100%;
  width: 100%;
}

.table-primary{

    --bs-table-accent-bg{
        background:white;
    }

}

.dropdown{
    display: flex;
}

#dropdown-lang{
   padding-top: 3px;
}

.dropdown-menu-lang{
    min-width:0;
}

// LOGIN

.App-login{
  

  h1{
      margin-bottom: .2rem;
  }
  
  h2{
      color:$primary;
  }

  .btn-submit{
      font-size: 1.5625rem;
      .icon-login{
          position: relative;
          top: 2px;
          margin-left: 2px;
          color:$primary;
      }
  }


}



// AUTH PAGE

.auth-page{

  
  .App-header{
    flex-grow: 1;
  }
  .App-container{
    flex-grow: 2;

    & > *{
      padding: 3rem;
    }

  }
}



// DASHBOARD

.dashboard{

  h1{
    font-size: 3rem;
  }

  .btn{
    font-size: 2.5rem;
    display: flex;
    color:$white;
    padding:0;
    border:2px solid $primary;
    margin-bottom: 2rem;
    i{
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $primary;
    }

    &:hover{
      background-color: $primary;
      i{
        
      }
    }
  }
}

// PRODUCTS



.products{

  .btn-category{

    position: relative;

    .caption{
      position: absolute;
      left:0;
      right:0;
      padding:.5rem;
      background-color: $primary;
      font-weight: 700;
      color:$white;      
      transition:all .7s;
      bottom:0;
      font-size:.9rem;
    }

    &:hover{
      .caption{
        background-color: $secondary;
      }
    }
  }

  .item{
    position: relative;
    .ratio{
      display: block;
    }
    .add-to-cart{
      position: absolute;
      right: 0;
      bottom: 0;
      width:70px;
      height: 50px;
      z-index: 100;
      font-size: 1.5rem;
      padding:0rem .5rem;
    }

    .cart-qty{

      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 100;
      width:70px;
      
      .btn{
        font-size: 1.5rem;
      }

    }

    .price{
        
    }
  }

  

  .pagination{

    .page-item{
      
      

      .page-link{
        color:$white;
        background-color: transparent;
        border:none;
        font-size: 2rem;

      }

     
      &.active .page-link{
        font-weight: 700;
      }
      

    }
  }
}

.product-single{

    .description{
        legend{
        color: $gray-400;
        font-size: 1rem;
        font-style: italic;
        }

        .swiper-slide.infos{
        background-color: $primary;
        }
    }
    

  
}

.product-single, .cart-page, .order-new {
  

    .cart-update-qty input{
        height: 38px;
    }
}



// ORDERS

.order-new, .customer-new{

  footer{

    .btn{
      color:$white;
      &.active, &:hover{
        border:2px solid $white;
      }
      i{
        vertical-align: middle;
      }
    }
  }
}

.swiper-creative .swiper-slide{
    overflow: visible;
}

.swiper-slide:not(.swiper-slide-active){
    pointer-events: none;
}

.custom-select{
    font-size: .7rem;
    text-align: left;
}

.customer-item{

    position: relative;

    .btn-group{
        position:absolute;
        right: 5px;
        top:5px;
    }
}

.orders-form{
    .img-wrapper{
        width:50px;
        height:50px;
    }
}

.cart-update-qty{

   div:first-child{
    overflow: visible !important;
   }

   .btn{
    width: 40px;
   }

   input{
    --webkit-appearance: none;
    border-radius: 0;

   }

}


.gallery-wrapper .img{
    max-height: 500px;
    background-color: white;

    &.notfound{
        background-color: #acacac;
    }

    
}
.was-validated .form-control:invalid, .form-control.is-invalid{
    border-width: 2px;
}

.react-confirm-alert-body {

    width:600px;
}
.react-confirm-alert-overlay {

    background-color: rgba(0,0,0,.7);

}